/**
 * Get a subset of the data list, based on the page number
 *
 * It can handle partial page navigation, such as showing a page of 10 elements, but only adding new 5 elements on the
 * next page (and removing 5 elements from the previous page). This is useful for navigating with arrows instead of page
 * numbers, because you can show several elements in the same page, but only moving one element at a time instead of the
 * whole page.
 *
 * @param {Object[]} dataset - the data to paginate
 * @param {number} currentPage - the current page to show
 * @param {number} elementsPerPage - the number of elements per page to show
 * @returns {Object[]} list of the paginated (subset of) items
 */
export default (dataset, currentPage, elementsPerPage) => {
    // Get the first and last elements to display based on the page
    const initialElement = elementsPerPage * (currentPage - 1);
    const finalElement = initialElement + elementsPerPage;

    // If the last element is higher than the total number of elements, it means we are in the last page, so we only
    // display until the last item.
    const maxIteration = Math.min(finalElement, dataset.length);

    // Return the subset of items related to the specified page
    return dataset.slice(initialElement, maxIteration);
};
