import React from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next';

import {breakpoints, colors, ContentContainer} from '../../../styles/theme';
import SwitchWayBg from '../../../images/about/careers/switch-way-bg.png';
import {H1, Highlighted, P, PMono} from '../../common/Typography';


/*
 * Private Elements
 */
const StyledSwitchWay = styled.div`
    background-color: ${colors.white};

    ${ContentContainer} {
        padding-top: 80px;
        padding-bottom: 100px;
    }

    @media (min-width: ${breakpoints.md}) {
        background: ${colors.white} url(${SwitchWayBg}) scroll no-repeat right 100px;
        background-size: contain;

        ${ContentContainer} {
            padding: 100px 80px 80px;
        }
    }
`;

const StyledSwitchValue = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 50px;
    padding-right: 25px;

    .idx {
        line-height: 44px;
        color: ${colors.darkPurple};
        margin-bottom: 10px;
        margin-right: 5px;
    }

    .title {
        font-size: 26px;
        line-height: 36px;
    }

    ${P} {
        color: ${colors.black};
    }
`;

const SwitchValue = ({idx, title, content}) => (
    <StyledSwitchValue>
        <PMono>
            <span className="idx">
                {idx}
                .
            </span>
            <span className="title">{title}</span>
        </PMono>

        <P>{content}</P>
    </StyledSwitchValue>
);

SwitchValue.propTypes = {
    idx: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
};


/*
 * Public Elements
 */
const SwitchWay = () => {
    const {t} = useTranslation();

    const switchValues = [
        {
            idx: '01',
            title: t('Simplicity'),
            content: t(
                'Switch stands for simplicity — it is the shortest path to a solution. Because we don’t try to overfit,'
                + ' our product is adaptable to unpredictable future challenges.',
            ),
        },
        {
            idx: '02',
            title: t('Selflessness'),
            content: t(
                'Cognitive diversity is always welcome, but when debating internally, we make sure to leave our egos '
                + 'outside. The idea that best serves Switch’s interests will always win.',
            ),
        },
        {
            idx: '03',
            title: t('Ownership'),
            content: t(
                'It’s never not our problem: we proactively approach challenges on our own. Here’s the catch though: '
                + '"with great power comes great responsibility".',
            ),
        },
        {
            idx: '04',
            title: t('Greatness'),
            content: t(
                'We don\'t just deliver, we make sure we exceed ourselves while doing so. We understand that going the '
                + 'extra mile leads to a positive mental attitude and builds loyalty around us.',
            ),
        },
        {
            idx: '05',
            title: t('Grit'),
            content: t(
                'We tirelessly work our way through challenges, failures, and adversity to achieve set goals. We do '
                + 'this because we understand that perseverance and passion — not intelligence — are the route for '
                + 'success.',
            ),
        },
        {
            idx: '06',
            title: t('Communication'),
            content: t(
                'We talk clearly, respectfully and honestly. We treasure transparency over BS, so make things easy for '
                + 'everyone and just speak your mind.',
            ),
        },
        {
            idx: '07',
            title: t('Focus'),
            content: t(
                'We mindfully choose continuous improvement over delayed perfection. This means we pragmatically '
                + 'prioritize tasks for maximum short-term output.',
            ),
        },
    ];

    const formattedSwitchValues = switchValues.map(value => (
        <div className="col-12 col-md-6 col-lg-4" key={value.idx}>
            <SwitchValue
                idx={value.idx}
                title={value.title}
                content={value.content}
            />
        </div>
    ));

    return (
        <StyledSwitchWay>
            <ContentContainer>
                <H1><Highlighted><Trans>The Switch Way</Trans></Highlighted></H1>

                <div className="row no-gutters">
                    {formattedSwitchValues}
                </div>
            </ContentContainer>
        </StyledSwitchWay>
    );
};


/*
 * Exports
 */
export default SwitchWay;
