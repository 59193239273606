import React from 'react';

import styled from '@emotion/styled';
import {Trans} from 'gatsby-plugin-react-i18next';

import {breakpoints, colors, ContentContainer, fontWeights} from '../../../styles/theme';
import {H1, H4, P2, PMono, h1BaseStyle, P} from '../../common/Typography';
import NoiseBackgroundImage from '../../../images/product/analytics/noise.png';
import AnimatedBackground from '../../common/AnimatedBackground';


/*
 * Private Elements
 */
const StyledPerks = styled.div`
    background-color: ${colors.black};
    color: ${colors.white};

    ${ContentContainer} {
        padding: 0;

        .animated-background {
            padding: 20px;

            ${P2} {
                font-size: 12px;
                line-height: 15px;
                text-transform: uppercase;
                letter-spacing: 3px;
                font-weight: ${fontWeights.bold};
                margin-top: 30px;
                margin-bottom: 30px;
            }

            ${H1} {
                ${h1BaseStyle};
                margin-bottom: 100px;
            }
        }
      
        .perk {
            p {
                font-weight: ${fontWeights.regular};
            }
        }
      
    }

    @media (min-width: ${breakpoints.md}) {
        ${ContentContainer} {
            padding: 0;

            .animated-background {
                padding: 60px;
            }
        }
    }
`;

const StyledNoiseBackground = styled.div`
    width: 100%;
    padding: 50px 15px 30px;
    background: repeat url(${NoiseBackgroundImage}), linear-gradient(45deg, #000000 0%, ${colors.darkPurple} 250%);

    .perk {
        padding: 10px;

        ${H4} {
            margin-bottom: 20px;
        }

        ${PMono} {
            margin-bottom: 25px;
        }
    }

    @media (min-width: ${breakpoints.md}) {
        margin: -30px 0;
        padding: 80px 70px 40px;

        .perk {
            padding: 25px;
        }
    }
`;


/*
 * Public Elements
 */
const Perks = () => (
    <StyledPerks>
        <ContentContainer>
            <div className="row no-gutters">
                <div className="col-12 col-md-4">
                    <AnimatedBackground>
                        <P2><Trans>Work at Switch</Trans></P2>
                        <H1><Trans>Perks you&apos;ll have.</Trans></H1>
                    </AnimatedBackground>
                </div>
                <div className="col-12 col-md-8">
                    <StyledNoiseBackground>
                        <div className="row no-gutters">
                            <div className="col-6 col-lg-4 perk">
                                <H4 as="p"><Trans>Healthy Choices.</Trans></H4>
                                <P>
                                    <Trans>
                                        Fresh fruit, healthy snacks, and warm soup always available at the office.
                                        Partnerships with gyms, surf schools, pharmacies, and nutrition supplement
                                        shops.
                                    </Trans>
                                </P>
                            </div>
                            <div className="col-6 col-lg-4 perk">
                                <H4 as="p"><Trans>Medical Care.</Trans></H4>
                                <P>
                                    <Trans>
                                        Free health insurance program with dental plan included. Coverage in most
                                        clinics and hospitals.
                                    </Trans>
                                </P>
                            </div>
                            <div className="col-6 col-lg-4 perk">
                                <H4 as="p"><Trans>Switch Weekend.</Trans></H4>
                                <P>
                                    <Trans>
                                        Yearly retreats in different locations in Portugal. Time off from work for
                                        leisure with the team.
                                    </Trans>
                                </P>
                            </div>
                            <div className="col-6 col-lg-4 perk">
                                <H4 as="p"><Trans>Tune In.</Trans></H4>
                                <P>
                                    <Trans>
                                        Netflix and Spotify Premium accounts. No more ads in your playlists and never
                                        missing the show everyone’s talking about.
                                    </Trans>
                                </P>
                            </div>
                            <div className="col-6 col-lg-4 perk">
                                <H4 as="p"><Trans>Learning Resources.</Trans></H4>
                                <P>
                                    <Trans>
                                        Free online programming courses for newcomers, and access to more than 200 books
                                        on the Switch Library.
                                    </Trans>
                                </P>
                            </div>
                            <div className="col-6 col-lg-4 perk">
                                <H4 as="p"><Trans>Chilling Outside.</Trans></H4>
                                <P>
                                    <Trans>
                                        Regular hangouts at the park. Parque da Cidade in Porto or Parque Urbano do
                                        Jamor in Lisbon are 2 minutes away by foot from the office.
                                    </Trans>
                                </P>
                            </div>
                        </div>
                    </StyledNoiseBackground>
                </div>
            </div>
        </ContentContainer>
    </StyledPerks>
);


/*
 * Exports
 */
export default Perks;
