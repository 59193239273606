import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {graphql} from 'gatsby';

import {useTranslation} from 'gatsby-plugin-react-i18next';

import {ContrastContext} from '../../../helpers/context';
import SEO from '../../../components/common/SEO';
import EmployeeTestimonies from '../../../components/about/careers/EmployeeTestimonies';
import HeroHeader from '../../../components/about/careers/HeroHeader';
import Perks from '../../../components/about/careers/Perks';
import SwitchWay from '../../../components/about/careers/SwitchWay';
import WhatWeHaveBeenDoing from '../../../components/about/careers/WhatWeHaveBeenDoing';


/*
 * Public Elements
 */
const CareersPage = ({data}) => {
    const setContrast = useContext(ContrastContext);
    setContrast(false);
    const {t} = useTranslation();

    const normalizedEmployeeTestimonies = data.allStrapiEmployeeTestimony.edges.map(testimony => testimony.node);

    return (
        <>
            <SEO
                title={t('Careers')}
                ogTitle={t('Careers at Switch')}
                description={t('Learn more about the Switch culture.')}
                image="/website/metatags/about-careers.png"
            />

            <HeroHeader />

            <SwitchWay />

            {normalizedEmployeeTestimonies.length > 0 && (
                <EmployeeTestimonies data={normalizedEmployeeTestimonies} />
            )}

            <Perks />

            <WhatWeHaveBeenDoing />
        </>
    );
};

CareersPage.propTypes = {
    data: PropTypes.object.isRequired,
};


/*
 * Exports
 */
export default CareersPage;
export const query = graphql`
    query($language: String!) {
        allStrapiEmployeeTestimony(filter: {language: {eq: $language}}) {
            edges {
                node {
                    title
                    content
                    employee_name
                    employee_title
                    employee_photo {
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    placeholder: BLURRED
                                    layout: FIXED
                                    width: 70
                                )
                            }
                        }
                    }
                }
            }
        }
    }
`;
