/** @jsx jsx */
import React, {useContext, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {graphql, useStaticQuery} from 'gatsby';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';

import {jsx} from '@emotion/react';
import styled from '@emotion/styled';
import {Trans} from 'gatsby-plugin-react-i18next';

import {ScreenSizeContext} from '../../../helpers/context';
import {breakpoints, colors, ContentContainer} from '../../../styles/theme';
import {Hero, Highlighted} from '../../common/Typography';


/*
 * Constants
 */
const mdBreakpoint = parseInt(breakpoints.md.replace('px', ''), 10);
export const IGSlugsQuery = graphql`
    query {
        allStrapiInstagramPost(limit: 8, sort: {fields: createdAt, order: DESC}) {
            nodes {
                image {
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                placeholder: BLURRED
                                width: 350
                            )
                        }
                    }
                }
            }
        }
    }
`;



/*
 * Private Elements
 */
const StyledWhatWeHaveBeenDoing = styled.div`
    overflow-x: hidden;
    padding-bottom: 20px;

    ${ContentContainer} {
      ${Hero} {
          &.title {
              margin: 100px auto 50px;
              width: fit-content;
              text-align: center;
          }
      }
    }

    @media (min-width: ${breakpoints.md}) {
        ${ContentContainer} {
            ${Hero} {
                &.title {
                    margin: 180px auto 120px;
                }
            }
        }
    }
`;

const StyledInstagramCard = styled.div`
    box-shadow: -6px 12px 15px 0 ${colors.shadowGrey};
`;

const InstagramCard = ({post, innerRef = null}) => (
    <StyledInstagramCard className="instagram-card" ref={innerRef}>
        <GatsbyImage image={getImage(post.image.localFile)} alt="" />
    </StyledInstagramCard>
);

InstagramCard.propTypes = {
    post: PropTypes.object.isRequired,
    innerRef: PropTypes.object,
};

InstagramCard.defaultProps = {
    innerRef: null,
};

const StyledMobileIGPosts = styled.div`
    display: flex;
    flex-direction: column;

    .column {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-grow: 1;
      
        &.middle {
            width: calc(100% + 50px);
            margin-left: -25px;

            .posts {
                display: flex;
                flex-direction: row;
                width: 125%;
                flex-shrink: 0;

                .instagram-card  {
                    width: 50%;
                }
            }
        }

        .instagram-card  {
            margin: 10px 5px;
            flex-basis: 0;
            flex-grow: 1;
        }
    }
`;

const MobileIGPosts = ({posts}) => (
    <StyledMobileIGPosts>
        <div className="column">
            <InstagramCard post={posts[0]} />
            <InstagramCard post={posts[1]} />
        </div>
        <div className="column middle">
            <div className="posts">
                <InstagramCard post={posts[2]} />
                <InstagramCard post={posts[3]} />
                <InstagramCard post={posts[4]} />
            </div>
        </div>
        <div className="column">
            <InstagramCard post={posts[5]} />
            <InstagramCard post={posts[6]} />
        </div>
    </StyledMobileIGPosts>
);

MobileIGPosts.propTypes = {
    posts: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
};

const StyledDesktopIGPosts = styled.div`
    display: flex;
    flex-direction: row;
    padding-left: 25px;
    padding-right: 25px;

    .column {
        display: flex;
        flex-direction: column;
        margin: 0 5px 0;
        flex-basis: 0;
        flex-grow: 1;

        .instagram-card,
        .spacer {
            margin-bottom: 15px;
        }
    }

    @media (min-width: ${breakpoints.lg}) {
        padding-left: 65px;
        padding-right: 65px;

        .column {
            margin: 0 10px 0;

            .instagram-card,
            .spacer {
                margin-bottom: 30px;
            }
        }
    }
`;

const DesktopIGPosts = ({posts}) => {
    const igCardRef = useRef(null);
    const [css, setCss] = useState({});

    const imgCardHeight = igCardRef.current?.offsetHeight;
    useEffect(() => {
        setCss({
            // eslint-disable-next-line i18next/no-literal-string
            '.spacer': {
                height: `${igCardRef.current.offsetHeight / 2}px`,
            },
        });
    }, [imgCardHeight]);

    return (
        <StyledDesktopIGPosts css={css}>
            <div className="column">
                <InstagramCard post={posts[0]} innerRef={igCardRef} />
                <InstagramCard post={posts[1]} />
                <div className="spacer" />
            </div>
            <div className="column">
                <div className="spacer" />
                <InstagramCard post={posts[2]} />
                <div className="spacer" />
                <div className="spacer" />
            </div>
            <div className="column">
                <div className="spacer" />
                <InstagramCard post={posts[3]} />
                <InstagramCard post={posts[4]} />
            </div>
            <div className="column">
                <InstagramCard post={posts[5]} />
                <InstagramCard post={posts[6]} />
                <div className="spacer" />
            </div>
            <div className="column">
                <InstagramCard post={posts[7]} />
                <div className="spacer" />
                <div className="spacer" />
                <div className="spacer" />
            </div>
        </StyledDesktopIGPosts>
    );
};

DesktopIGPosts.propTypes = {
    posts: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
};


/*
 * Public Elements
 */
const WhatWeHaveBeenDoing = () => {
    const instagramPosts = useStaticQuery(IGSlugsQuery);
    const screenSize = useContext(ScreenSizeContext);

    const InstagramPostsElement = screenSize.width < mdBreakpoint ? MobileIGPosts : DesktopIGPosts;

    return (
        <StyledWhatWeHaveBeenDoing>
            <ContentContainer>
                <Hero className="title"><Highlighted><Trans>What we&apos;ve been doing 👋🏼:</Trans></Highlighted></Hero>

                <InstagramPostsElement posts={instagramPosts.allStrapiInstagramPost.nodes} />
            </ContentContainer>
        </StyledWhatWeHaveBeenDoing>
    );
};


/*
 * Exports
 */
export default WhatWeHaveBeenDoing;
