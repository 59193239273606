/** @jsx jsx */
import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';

import {css, jsx} from '@emotion/react';
import {BgImage} from 'gbimage-bridge';
import {getImage} from 'gatsby-plugin-image';
import {Trans} from 'gatsby-plugin-react-i18next';

import {Hero} from '../../common/Typography';
import {colors} from '../../../styles/theme';


/*
 * Constants
 */
const imageQuery = graphql`
    query {
        hero: file(relativePath: { eq: "about/careers/hero.jpg" }) {
            childImageSharp {
                gatsbyImageData(
                    placeholder: BLURRED
                    width: 1400
                    quality: 95
                )
            }
        }
    }
`;


/*
 * Private Elements
 */
const heroHeaderCss = css`
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: ${colors.white};

    ${Hero} {
        max-width: 750px;
        text-align: center;
    }
`;


/*
 * Public Elements
 */
const HeroHeader = () => {
    const imageElement = useStaticQuery(imageQuery);

    return (
        <BgImage image={getImage(imageElement.hero)} css={heroHeaderCss}>
            <Hero><Trans>Do you think you have what it takes?</Trans></Hero>
        </BgImage>
    );
};


/*
 * Exports
 */
export default HeroHeader;
