import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';

import styled from '@emotion/styled';
import {Trans} from 'gatsby-plugin-react-i18next';

import {breakpoints, colors, ContentContainer} from '../../../styles/theme';
import {BlockQuote, H2, P, PMono} from '../../common/Typography';
import usePagination from '../../../helpers/hooks/usePagination';
import {ArrowCircleButton} from '../../common/Clickables';


/*
 * Private Elements
 */
const StyledEmployeeTestimonies = styled.div`
    background-color: ${colors.black};
    clip-path: polygon(0 100px, 100% 0, 100% 100%, 0 100%);
    margin-top: -100px;
    margin-bottom: -1px;
    padding-top: 150px;
    padding-bottom: 80px;
    color: ${colors.white};

    ${ContentContainer} {
        padding-right: 45px;
        padding-left: 45px;

        .story-to-tell {
            margin-bottom: 50px;
        }

        .navigation {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;

            .arrow {
                margin-right: 30px;
            }
        }
    }

    @media (min-width: ${breakpoints.lg}) {
        clip-path: polygon(0 150px, 100% 0, 100% 100%, 0 100%);
        padding-top: 250px;

        ${ContentContainer} {
            .higher {
                margin-top: -75px;
            }
        }
    }
`;

const StyledTestimony = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px 0;
    margin-bottom: 60px;

    ${H2} {
        margin-bottom: 50px;
    }

    .blockquote {
        margin-left: -20px;
        margin-bottom: 15px;
    }

    .signature {
        display: flex;
        flex-direction: row;
        align-items: center;

        .gatsby-image-wrapper {
            width: 45px;
            height: 45px;
            border-radius: 50%;
            margin-bottom: 20px;
        }

        .employee-data {
            display: flex;
            flex-direction: column;

            ${PMono} {
                margin-left: 25px;
                margin-bottom: 0;

                &.employee-name {
                    color: ${colors.darkPurple};
                }
            }
        }
    }

    @media (min-width: ${breakpoints.lg}) {
        padding: 15px 35px;

        .blockquote {
            margin-left: 0;
        }

        .signature {
            .gatsby-image-wrapper {
                width: 70px;
                height: 70px;
                margin-left: 35px;
                margin-bottom: 0;
            }

            .employee-data {
                flex-direction: row;
            }
        }
    }
`;

const Testimony = ({title, content, photo, employeeName, employeeTitle}) => (
    <StyledTestimony>
        <H2 as="p" className="d-none d-md-block">{title}</H2>

        <BlockQuote><P>{content}</P></BlockQuote>

        <div className="signature">
            <GatsbyImage image={getImage(photo)} alt={employeeName} imgStyle={{borderRadius: '50%'}} />
            <div className="employee-data">
                <PMono className="employee-name">{employeeName}</PMono>
                <PMono className="employee-title">{employeeTitle}</PMono>
            </div>
        </div>
    </StyledTestimony>
);

Testimony.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    photo: PropTypes.object.isRequired,
    employeeName: PropTypes.string.isRequired,
    employeeTitle: PropTypes.string.isRequired,
};


/*
 * Public Elements
 */
const EmployeeTestimonies = ({data}) => {
    const [currentPage, setCurrentPage] = useState(1);
    const paginatedTestimonies = usePagination(data, currentPage, 2);

    return (
        <StyledEmployeeTestimonies>
            <ContentContainer>
                <H2 className="d-md-none story-to-tell"><Trans>It&apos;s your story to tell.</Trans></H2>

                <div className="row no-gutters">
                    {paginatedTestimonies.map((testimony, idx) => (
                        <div className={idx === 1 ? 'col-12 col-md-6 higher' : 'col-12 col-md-6'} key={testimony.title}>
                            <Testimony
                                employeeName={testimony.employee_name}
                                employeeTitle={testimony.employee_title}
                                content={testimony.content}
                                title={testimony.title}
                                photo={testimony.employee_photo.localFile}
                            />
                        </div>
                    ))}
                </div>

                <div className="navigation">
                    <ArrowCircleButton
                        onClick={() => setCurrentPage(currentPage - 1)}
                        className="arrow"
                        disabled={currentPage === 1}
                        invertedDirection
                    />
                    <ArrowCircleButton
                        onClick={() => setCurrentPage(currentPage + 1)}
                        className="arrow"
                        disabled={currentPage === Math.ceil(data.length / 2)}
                    />
                </div>
            </ContentContainer>
        </StyledEmployeeTestimonies>
    );
};

EmployeeTestimonies.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
};


/*
 * Exports
 */
export default EmployeeTestimonies;
